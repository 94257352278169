// Generated by Framer (b75150a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./Jd3J7JZ9L-0.js";

const cycleOrder = ["vWkRwEXc6"];

const serializationHash = "framer-FC16O"

const variantClassNames = {vWkRwEXc6: "framer-v-1c7eszy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, pE6i3nzkK: title ?? props.pE6i3nzkK ?? "Prerequisites"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pE6i3nzkK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "vWkRwEXc6", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1c7eszy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vWkRwEXc6"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7SW50ZXItNjAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "40px", "--framer-font-weight": "600", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-1of0zx5, var(--token-c384ea98-c220-4ce5-92ce-946ca1a48e04, rgb(226, 226, 227)))"}}>Prerequisites</motion.h2></React.Fragment>} className={"framer-tzzz7j"} fonts={["GF;Inter-600"]} layoutDependency={layoutDependency} layoutId={"OpLY4ffo9"} style={{"--extracted-1of0zx5": "var(--token-c384ea98-c220-4ce5-92ce-946ca1a48e04, rgb(226, 226, 227))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={pE6i3nzkK} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FC16O.framer-1031a7g, .framer-FC16O .framer-1031a7g { display: block; }", ".framer-FC16O.framer-1c7eszy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 16px 0px; position: relative; width: 744px; }", ".framer-FC16O .framer-tzzz7j { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FC16O.framer-1c7eszy { gap: 0px; } .framer-FC16O.framer-1c7eszy > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-FC16O.framer-1c7eszy > :first-child { margin-left: 0px; } .framer-FC16O.framer-1c7eszy > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 72
 * @framerIntrinsicWidth 744
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"pE6i3nzkK":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJd3J7JZ9L: React.ComponentType<Props> = withCSS(Component, css, "framer-FC16O") as typeof Component;
export default FramerJd3J7JZ9L;

FramerJd3J7JZ9L.displayName = "h2 container";

FramerJd3J7JZ9L.defaultProps = {height: 72, width: 744};

addPropertyControls(FramerJd3J7JZ9L, {pE6i3nzkK: {defaultValue: "Prerequisites", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerJd3J7JZ9L, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZ1rib2Bg-4.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})